import React, { useState, useEffect,Fragment } from "react";
import BoPaymentWrapper from "../components/BoPaymentLeftWrapper";
import { history } from "../helpers/history";
import { useDispatch, useSelector } from "react-redux";
import { loginData} from "../actions/auth";
import { Redirect, Link } from "react-router-dom";
import BoLoader from "./BoLoader";

const EVerifyAuth = (props) => {
   
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { message } = useSelector((state) => state.message);
    const [loading, setLoading] = useState(false);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const userId = params.get("userId");
    useEffect(()=>{
        getAuthVerification();
    },[])
    // if (isLoggedIn) {
    //     return <Redirect to="/dashboard" />;
    //   }
    
    const getAuthVerification=()=>{
        setLoading(true);
        dispatch(loginData(userId))
          .then(() => {
            setLoading(false);
            history.push("/dashboard");
            setLoading(false);
          })
          .catch((err) => {
            history.push("/login");
            setLoading(false);
            // setconditionalClassName("onbo-content-top");
          });
      } 
    

    return (
        <Fragment>
             <BoLoader loading={loading} />
            <div className="row minvh-100">
            {/* <BoPaymentWrapper
          pagename={["Login", <br />, "In Progress !"]}
          pagemsg={"Welcome please wait while we process your request"}
        /> */}
            {/* <div className="col-md-7 bo-on-screens position-relative onbo-content-center minh-row-mbl">
            {message && (
              <div className="form-group">
                <div className="col-sm-12 col-md-10 alert-messages alert-info alert-mbl">
                  <span className="icon-info"></span>

                  <p>{message}</p>
                </div>
              </div>
            )}
                <div className="col-md-8 col-lg-5 mt-60">
                    <div className="icon-alert">
                        <span className="icon-loading">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </span>
                    </div>
                    <h3 className="mb-4">Verification in progress please wait !</h3>
                    <div className="form-group submit-field mt-40">
                        <button
                            className="btn btn-large w-100"
                            onClick={() => history.push("/login")}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div> */}
            </div>
        </Fragment>
    );
}
export default EVerifyAuth;