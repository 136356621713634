import React, { useState, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useDispatch, useSelector } from "react-redux";
import BoPaymentWrapper from "../BoPaymentLeftWrapper";
import BoLoader from "../../helpers/BoLoader";
import useWindowDimensions from "../../helpers/WindowWidth";
import DialoguePopup from "../../helpers/DialoguePopup";
import ModelPopup from "../../helpers/ModelPopup";
import { Link } from "react-router-dom";
import { logout } from "../../actions/auth";
import cardsService from "../../services/cards.services";
import { Redirect } from "react-router-dom";

import { LOGIN_SUCCESS } from "../../actions/types";
import { history } from "../../helpers/history";

const required = (value) => {
  if (!value) {
    document.getElementById("frgt_Pas_Email_FrmGrp").classList.add("has_error");
    return (
      <span className="help-block">Card Number is required!</span>
    );
  } else
    document
      .getElementById("frgt_Pas_Email_FrmGrp")
      .classList.remove("has_error");
};
const requiredKitNumber = (value) => {
  if (!value) {
    document.getElementById("kit_number").classList.add("has_error");
    return (
      <span className="help-block">Kit Number is required!</span>
    );
  } else
    document
      .getElementById("kit_number")
      .classList.remove("has_error");
};
const requiredCardOwnerName = (value) => {
  if (!value) {
    document.getElementById("card_owner_name").classList.add("has_error");
    return (
      <span className="help-block">Card Owner Name is required!</span>
    );
  } else
    document
      .getElementById("card_owner_name")
      .classList.remove("has_error");
};
const CardVerification = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const form = useRef();
  const { height, width } = useWindowDimensions();
  const checkBtn = useRef();
  const [CardNumber, setCardNumber] = useState("");
  const [KitNumber, setKitNumber] = useState("");
  const [CardOwnerName, setCardOwnerName] = useState("");
  const [UserId, setUserId] = useState(String(currentUser?.user?.UserId));
  const [successful, setSuccessful] = useState(false);
  const [boloader, setBoLoader] = useState(false);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();
  const onChangeCardNumber = (e) => {
    const number = e.target.value;
    if (number != "") {
      let value=number;
      setCardNumber(value);
    }

  };
  const onChangeKitNumber = (e) => {
    const number = e.target.value;
    if (number != "") {
      let value = number;
      setKitNumber(value);
    }

  };
  const onChangeKCardOwnerName = (e) => {
    const number = e.target.value;
    if (number != "") {
      let value = number;
      setCardOwnerName(value);
    }

  };
  const maxLengthCheck = object => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
    var t = object.target.value;
    if (t.includes("."))
      object.target.value = (t?.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
  };
  //Dialogue Popup
  const [modalShow, setModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [showmessgae, setshowmessgae] = useState(message);

  const submitCardVerification = (e) => {
    
    e.preventDefault();
    if (currentUser?.user?.IssuerName==="IssuerT")
      handleSubmitIssuerT();
    else{
      setSuccessful(false);
      form.current.validateAll();
      if (checkBtn.current.context._errors.length === 0) {
        setBoLoader(true);
        cardsService.ActivatePhysicalCard(String(CardNumber),String(KitNumber),
          UserId)
          .then(() => {
            setSuccessful(true);
            setBoLoader(false);
            setModalsuccessfullShow(true);
          })
          .catch((error) => {
            setSuccessful(false);
            setBoLoader(false);
            let msgBody = error?.response?.data?.message
              ? error?.response?.data?.message
  
              : "Error Occured";
            let errIcon = "icon-error";
            errorAndSuccessModl("Error", msgBody, errIcon);
          });
      }
    }
    
  };

  const handleSubmitIssuerT = () => {
    setSuccessful(false);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      setBoLoader(true);
      cardsService.assigncardT(String(CardNumber),String(CardOwnerName),
        UserId)
        .then(() => {
          setSuccessful(true);
          setBoLoader(false);
          setModalsuccessfullShow(true);
        })
        .catch((error) => {
          setSuccessful(false);
          setBoLoader(false);
          let msgBody = error?.response?.data?.message
            ? error?.response?.data?.message

            : "Error Occured";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
        });
    }
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  const logoutUser = () => {
    dispatch(logout());
    history.push("/login");
    window.location.reload();
    //return <Redirect to="/login" />;
  }
  const goToUser = () => {
    const obj = currentUser.user
    obj.IsCardActivate = true;
    console.log(`obj`, obj)
    dispatch({
      type: LOGIN_SUCCESS,
      payload: { user: obj },
    });
    history.push("/dashboard")
  }

  return (
    <div className="row min-vh-100">
      <BoLoader loading={boloader} />
      {width > 767 && <BoPaymentWrapper pagename={["Card", <br />, "Verification"]} />}

      <div className="col-md-7 col-lg-8 onbo-content onbo-content-mbl">
        <div className="row">
          <div className="bo-on-screens position-relative">
            <div className="col-md-8 mb-5">
              <h3 className="mb-4">Card Activation</h3>
              {(currentUser?.user?.IssuerName==="IssuerB4B" || currentUser?.user?.IssuerName==="IssuerT")  ?(<p>Please enter sixteen digits of your physical card number.</p>):(<p>
               Please enter last four digits your physical card number.
              </p>)}
            </div>
            <div className="col-md-8 col-lg-6 col-xl-5">
              <Form onSubmit={submitCardVerification} ref={form}>
                <div className="form-group" id="frgt_Pas_Email_FrmGrp">
                  <label htmlFor="email">{currentUser?.user?.IssuerName==="IssuerB4B"?"Card Number":"Last 4 Card Digit"}*</label>
                  <div className="form-controls">
                    <Input
                      maxLength={currentUser?.user?.IssuerName==="IssuerB4B"?"16":"4"}
                      onInput={maxLengthCheck}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      value={CardNumber}
                      onChange={onChangeCardNumber}
                      validations={[required]}
                      placeholder={currentUser?.user?.IssuerName==="IssuerB4B"?"Enter Card Number":"Enter Last 4 Card Digit"}
                    />
                    <span className="icon-email _ico"></span>
                    {/* {message && <span className="help-block has_error"> </span>} */}
                    {/* {success && <span className="help-block has_error"> {"message"}</span>} */}
                  </div>

                </div>
                { currentUser?.user?.IssuerName==="IssuerG"  &&(
                  <div className="form-group" id="kit_number">
                   <label htmlFor="email">Kit Number*</label>
                   <div className="form-controls">
                     <Input
                       maxLength="16"
                       onInput={maxLengthCheck}
                       onWheel={(e) => e.target.blur()}
                       type="number"
                       value={KitNumber}
                       onChange={onChangeKitNumber}
                       validations={[requiredKitNumber]}
                       placeholder="Enter 16 digit Kit Number"
                     />
                     <span className="icon-email _ico"></span>
                     
                   </div>
 
                 </div>
                )
                   
                }
                { currentUser?.user?.IssuerName==="IssuerT"  &&(
                  <div className="form-group" id="card_owner_name">
                   <label htmlFor="email">Card Owner Name*</label>
                   <div className="form-controls">
                     <Input
                       maxLength="50"
                       onInput={maxLengthCheck}
                       onWheel={(e) => e.target.blur()}
                       type="text"
                       value={CardOwnerName}
                       onChange={onChangeKCardOwnerName}
                       validations={[requiredCardOwnerName]}
                       placeholder="Card Owner Name is required !"
                     />
                     <span className="icon-email _ico"></span>
                     
                   </div>
 
                 </div>
                )
                   
                }
                <div className="row">
                <div className="col-xl-6 mt-4">
                  <div className="form-group submit-field">
                    <button className="btn btn-large w-100">verify card{" "}</button>
                  </div>
                </div>

                <div className="col-xl-6 mt-4">
                  <div className="form-group submit-field">
                    <button
                      className="btn btn-large w-100"
                      onClick={() => { sessionStorage.removeItem("user"); window.location.reload(); }}
                    >
                      Logout
                    </button>
                  </div>
                </div>
                </div>
               
                {/* <div className="form-group submit-field">
                  {!successful && (
                    <button className="btn btn-large w-100">
                      verify card{" "}
                    </button>

                  )}
                  <button className="btn btn-large w-100">
                    verify card{" "}
                  </button>
                </div> */}

                <CheckButton style={{ display: "none" }} ref={checkBtn} />
              </Form>
            </div>
          </div>
        </div>
      </div>
      <ModelPopup
        key={1}
        show={modalsuccessfullShow}
        onHide={() => { setModalsuccessfullShow(false);  logoutUser(); }}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>ALL Done! Please Procceed to login!</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                                logoutUser();
                              }}
                            >
                              Done
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      />
    </div>
  );
};
export default CardVerification;
