import axios from "axios";
import { Env } from "../enviroment/environment";

const API_URL = Env.AuthApiUrl; //"http://localhost:8000/api/auth/";
const API_URL_GINGR = Env.GingerApiUrl; //"http://localhost:8000/api/auth/";

const loginCardConsole = (email, password, rememberMe) => {
  return axios
    .post(API_URL + "login", {
      email,
      password,
      
    })
    .then((response) => {
      if (response.data.auth_token) {
        sessionStorage.setItem("user", JSON.stringify(response.data));
      }
      if (rememberMe) {
        localStorage.setItem("boUserEmail", email);
        localStorage.setItem("boUserPassword", password);
      }
      return response.data;
    });
};

const login = (email, password, rememberMe,EmailCode,SmsCode) => {
  return axios
    .post(API_URL + "postlogin", {
      email,
      password,
      EmailCode,SmsCode,
    })
    .then((response) => {
      if (response.data.auth_token) {
        sessionStorage.setItem("user", JSON.stringify(response.data));
      }
      if (rememberMe) {
        localStorage.setItem("boUserEmail", email);
        localStorage.setItem("boUserPassword", password);
      }
      return response.data;
    });
};

const logout = () => {
  sessionStorage.removeItem("user");
};
const refreshtoken = (accessToken) => {
  return axios.post(API_URL + "refreshtoken", { accessToken }).then((resp) => {
    return resp;
  });
};
const prelogin = (email, password) => {
  return axios
    .post(API_URL + "prelogin", {
      email,
      password,
    })
    .then((response) => {
      return response;
    });
};
const loginData = (UserId) => {
  // axios.defaults.headers.common = {
  //   "ApiKey": "SDAFG5G6FDSG5D4FG65D4SFG456DS54E",
  // };
  return axios
    .post(API_URL_GINGR + "logindata", {
      UserId
    },
    {
      headers: {
        "ApiKey": "SDAFG5G6FDSG5D4FG65D4SFG456DS54E",  // Set API key specifically for this request
      }
    })
    .then((response) =>  {
      if (response.data.auth_token) {
        sessionStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const authObj = {
  login,
  logout,
  refreshtoken,
  prelogin,
  loginCardConsole,
  loginData

};
export default authObj;
