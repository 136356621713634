import React, { useState, useRef, Fragment, useEffect } from "react";
import { history } from "../../helpers/history";
import validator from "validator";
import DateFormat from "../../helpers/DateFormat";
import crossImgs from "../../assets/images/icons/cross.svg";
import RefreshToken from "../../helpers/RefreshToken";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import emptyclient from "../../assets/images/icons/clients-empty.svg";
import transactionService from "../../services/transaction.service";
import { useSelector } from "react-redux";
import moment from "moment";
import ModelPopup from "../../helpers/ModelPopup";
import ExportToExcel from "../../helpers/ExportToExcel";
import CurrencyFormat from 'react-currency-format';
import HideTransaction from "../../helpers/HideTransactions";

const SearchGridInputData = [
  {
    Name: "",
    UserId: "",
    StartDate: "",
    EndDate: "",
    PageNumber: 0,
    // Size:10,
    scrolled: false,
    isFirstTime: true,
  },
];
var fontStyle = {
  textTransform: "uppercase",
};
var centreAlign = {
  textAlign: "center",
};
const B4BTransactionHeaders=[
    "DATE",
    "MERCHANT NAME",
    "AMOUNT",
    "TRANSACTION TYPE",
    "VIEW",
  ]

const SearchGridCardTransactions = props => {
  const [modalShow, setModalShow] = useState(false);
  const [name, setCompanyName] = useState("");
  const [StartDate, setDateFrom] = useState("");
  const [EndDate, setDateTo] = useState("");
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const { user: currentUser } = useSelector(state => state.auth);
  const [UserId, setUserId] = useState(String(currentUser?.user?.UserId));
  const [totalCount, setTotalCount] = useState(0);
  //Transaction Models Showing Values
  const [TransactionsType, setTransactionsType] = useState("");
  const [Status, setStatus] = useState("");
  const [CurrencyCode, setCurrencyCode] = useState("");
  const [Amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [ReferenceNumber, setReferenceNumber] = useState("");
  const [MerchantName, setMerchantName] = useState("");
  const [clientCurrency, setClientCurrency] = useState("");
  const [Description, setDescription] = useState("");
  //Model popup Transactions
  const [modalShowTransactions, setModalShowTransactions] = useState(false);
  const [Month, setMonth] = useState(0);
  const [Year, setYear] = useState(0);
  //Export To Excel
  const [dataset, setDataset] = useState();
  const styleObj = {
    fontSize: 10,
  };
  useEffect(() => {
    SearchClickHandler(null, true);
    checkClientCurrency();

  }, []);
  const handleInputSearchClick = e => {
    e.preventDefault();
    setModalShow(true);
    setSearchDisabled(true);
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
  };
  const dateFromHandler = e => {
    SearchGridInputData[0].StartDate = e;
    if (e) {
      let date = SearchGridInputData[0].StartDate;
      if (SearchGridInputData[0].StartDate !== "") {
        SearchGridInputData[0].StartDate = moment(
          SearchGridInputData[0].StartDate
        ).format("YYYY-MM-DD");
        setDateFrom(date);
      } else {
        setDateFrom(date);
      }
    }
  };
  const dateToHandler = e => {
    SearchGridInputData[0].EndDate = e;
    if (e) {
      let date = SearchGridInputData[0].EndDate;
      if (SearchGridInputData[0].EndDate !== "") {
        SearchGridInputData[0].EndDate = moment(
          SearchGridInputData[0].EndDate
        ).format("YYYY-MM-DD");
        setDateTo(date);
      } else {
        setDateTo(date);
      }
    }
  };
  const onChangeMonth = (e) => {
    const name = e.target.selectedOptions[0].value;
    setMonth(parseInt(name));
  }

  const onChangeYear = (e) => {
    const name = e.target.selectedOptions[0].value;
    setYear(parseInt(name));
  }
  const SearchClickHandler = (e, isFromUseEffect) => {
    setLoadingData(true);
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    if (isFromUseEffect) {
      SearchGridInputData[0].PageNumber = 0;
      setSearchData("");
      //setModalShow(true);
    }
    else
      setModalShow(false);
    setSearchDisabled(false);
    transactionService
      .searhGridTransactionsCards(
        UserId,
        SearchGridInputData[0].StartDate,
        SearchGridInputData[0].EndDate,
        SearchGridInputData[0].PageNumber
        //SearchGridInputData[0].Size,
      )
      .then(resp => {
        setLoadingData(false);
        if (resp && resp.data && resp.data.payload) {
          if (resp.data.success) {
            // if(isFromUseEffect)
            // setModalShow(true);
            // else
            setModalShow(false);
            setSearchDisabled(false);
            setTotalCount(resp.data.payload.result.item1.length);
          }
          if (
            resp.data.payload.result.item1.content.length > 0 ||
            searchData.length > 0
          ) {
            setTotalCount(resp.data.payload.result.item1.length);
            if (SearchGridInputData[0].scrolled) {
              let appendedData = searchData.concat(
                resp.data.payload.result.item1.content
              );
              setSearchData(appendedData);
              setDataset(appendedData);
            } else {
              let reversearray = resp.data.payload.result.item1.content;
              let filtereddata = reversearray.reverse()
              if (currentUser?.user?.IssuerName === "IssuerG")
                sortDateTransaction(filtereddata);
              else
                setSearchData(filtereddata);
              setDataset(reversearray);
            }
            setSearchGridSuccess(true);

            SearchGridInputData[0].scrolled = false;
            // if (SearchGridInputData[0].isFirstTime) {
            searchTbl.current.onscroll = tblScrollHandler;
            SearchGridInputData[0].isFirstTime = false;
            // }
          }
        }
      })
      .catch(error => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        setTotalCount(0);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            SearchClickHandler();
          }
        })();
      });
  };
  const tblScrollHandler = e => {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].PageNumber + 1 <= totalCount - 1
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].PageNumber = SearchGridInputData[0].PageNumber + 1;
      SearchClickHandler();
    }
  };
  const renderTableHeader = () => {
    let header;
    if( currentUser?.user?.IssuerName === "IssuerB4B" && currentUser?.user?.ClientCurrency==="USD" && currentUser?.user?.IsPhysicalCard)
      {
          header=B4BTransactionHeaders;
      }
    else
     header = Object.values(props.headersdata);
    return header.map((key, index) => {
      // if (key === "DATE")
      //   return (
      //     <th style={centreAlign} key={index}>
      //       {key}
      //     </th>
      //   );
      // else
     
      if (currentUser?.user?.IssuerName === "IssuerG" && key == "STATUS")
        return
      else if (currentUser?.user?.IssuerName === "IssuerG" && key == "MERCHANT NAME")
        return <th key={index}>{"TXN DESCRIPTION".toUpperCase()}</th>;
      else
        return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const GetCardTransactionsspecificmonth = (e, isFromUseEffect) => {
    setLoadingData(true);
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    if (isFromUseEffect) {
      SearchGridInputData[0].PageNumber = 0;
      setSearchData("");
    }
    setModalShow(false);
    setSearchDisabled(false);
    transactionService
      .GetCardTransactionsspecificmonth(
        UserId,
        Month,
        Year
      )
      .then((resp) => {
        setLoadingData(false);
        if (resp && resp.data && resp.data.payload) {
          if (resp.data.success) {
            setModalShow(false);
            setSearchDisabled(false);
            setTotalCount(resp.data.payload.result.item1.totalElements);
          }
          if (
            resp.data.payload.result.item1.content.length > 0 ||
            searchData.length > 0
          ) {
            setTotalCount(resp.data.payload.result.item1.totalElements);
            if (SearchGridInputData[0].scrolled) {
              let appendedData = searchData.concat(
                resp.data.payload.result.item1.content
              );
              setSearchData(appendedData);
            } else {
              let reversearray = resp.data.payload.result.item1.content;
              let filtereddata = reversearray.reverse()
              if (currentUser?.user?.IssuerName === "IssuerG")
                sortDateTransaction(filtereddata);
              else
                setSearchData(filtereddata);
              SearchGridInputData[0].PageNumber = 0;
              //props?.setDataset(reversearray);
            }
            setSearchGridSuccess(true);

            SearchGridInputData[0].scrolled = false;
            // if (SearchGridInputData[0].isFirstTime) {
            searchTbl.current.onscroll = tblScrollHandler;
            SearchGridInputData[0].isFirstTime = false;
            // }
          }
        }
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        setTotalCount(0);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            GetCardTransactionsspecificmonth();
          }
        })();
      });
  };
  const showTransactionsPopup = (
    transactionType,
    merchantName,
    status,
    authAmount,
    authCurrencyCode,
    dateOfTransaction,
    retrievalReferenceNumber,
    description,
    transactionResponse
  ) => {
    setModalShowTransactions(true);
    setTransactionsType(transactionType);
    setStatus(status);
    setCurrencyCode(authCurrencyCode);
    let amount = calculateB4BFee(transactionResponse, authAmount);
    setAmount(amount);
    setDate(dateOfTransaction);
    setReferenceNumber(retrievalReferenceNumber);
    setMerchantName(merchantName);
    setDescription(description)

  };
  const checkClientCurrency = () => {
    if (currentUser?.user?.ClientCurrency === "USD")
      setClientCurrency("USD")
    else if (currentUser?.user?.ClientCurrency === "EUR")
      setClientCurrency("EUR")
    else if (currentUser?.user?.ClientCurrency === "GBP")
      setClientCurrency("GBP")
    else if (currentUser?.user?.ClientCurrency === "CAD")
      setClientCurrency("CAD")
    else
      setClientCurrency("")
  }
  const renderTableData = () => {
    return searchData.map((data, index) => {
      const {
        transactionType,
        merchantName,
        status,
        authCurrencyCode,
        authAmount,
        dateOfTransaction,
        retrievalReferenceNumber,
        transactionUuid,
        description, localDescription, transactionResponse,transactionId

      } = data; //destructuring
      if (transactionType?.toUpperCase() === "AUTHORIZATION_ADVICE" || transactionType?.toUpperCase() === "PIN_QUERY" || transactionType?.toUpperCase() === "PIN_CHANGE")
        return;

      if (removeTransactionsBilly(transactionId) === true) {
        return
      }
     
      return (
        <tr key={retrievalReferenceNumber}>
           <td>
            <DateFormat date={dateOfTransaction} />
          </td>
          {
            merchantName === null ? (
              <td style={fontStyle} title={transactionType} >{transactionType?.substr(0, 10)}{transactionType?.length > 10 && ("...")}</td>

            ) :
              <td style={fontStyle} title={merchantName} >{merchantName?.substr(0, 20)}{merchantName?.length > 20 && ("...")}</td>
          }
          {(currentUser?.user?.IssuerName !== "IssuerG" && currentUser?.user?.IssuerName !== "IssuerB4B")&& (<td style={fontStyle}>{status}</td>)}
          
          {currentUser?.user?.IssuerName === "IssuerB4B" ? (<>
            {
              authAmount < 0 ? (<td><CurrencyFormat value={authAmount} displayType={'text'} thousandSeparator={true} allowNegative={false} prefix="(" suffix=")" /></td>) : (
                <td style={fontStyle}><CurrencyFormat value={calculateB4BFee(transactionResponse, authAmount)} displayType={'text'} thousandSeparator={true} /> {authCurrencyCode}</td>
              )
              
            }
            
          </>
        ) : (<>

            {
              authAmount < 0 ? (<td><CurrencyFormat value={authAmount} displayType={'text'} thousandSeparator={true} allowNegative={false} prefix="(" suffix=")" /></td>) : (
                <td style={fontStyle}><CurrencyFormat value={authAmount} displayType={'text'} thousandSeparator={true} /> {authCurrencyCode}</td>
              )
            }
          </>)}
          {
            currentUser?.user?.IssuerName === "IssuerB4B" && currentUser?.user?.ClientCurrency==="USD" && currentUser?.user?.IsPhysicalCard &&(
              <>
             {
  status.toUpperCase() === "DECLINED"
    ? (<><td style={fontStyle}>{status}</td></>)
    : (
      <td style={fontStyle} title={transactionType}>
        {transactionType.toUpperCase() === "CARD_LOAD" ? "TOP UP" :
         transactionType.toUpperCase() === "BALANCE_ADJUSTMENT" ? "DEBIT" :
         transactionType?.substr(0, 13)}
        {transactionType.toUpperCase() !== "CARD_LOAD" && transactionType.toUpperCase() !== "BALANCE_ADJUSTMENT" && transactionType?.length > 13 && ("...")}
      </td>
    )
}
              </>  
            )
          }
         
          <td>
            <span
              className="icon-show"
              onClick={() =>
                showTransactionsPopup(
                  transactionType,
                  merchantName,
                  status,
                  authAmount,
                  authCurrencyCode,
                  dateOfTransaction,
                  retrievalReferenceNumber,
                  description,
                  transactionResponse
                )
              }
            ></span>
          </td>
        </tr>
      );
    });
  };

  const HideSearchPopupDiv = () => {
    setModalShow(false);
    setSearchDisabled(false);
    setCompanyName("");
  };

  const ClearSearchData = () => {
    if (currentUser?.user?.IssuerName === "IssuerG") {
      setDateFrom("");
      setDateTo("");
      if (document.getElementById("MonthIssuerG") !== null)
        document.getElementById("MonthIssuerG").value = 0;
      if (document.getElementById("YearIssuerG") !== null)
        document.getElementById("YearIssuerG").value = 0;
      setMonth(0);
      setYear(0);
      SearchClickHandler();
    }
    else {
      setCompanyName("");
      dateToHandler("");
      dateFromHandler("");
      setDateFrom("");
      setDateTo("");
      ClearInputData();
    }

  };

  const ClearInputData = () => {
    if (document.getElementById("clientNameDD") !== null)
      document.getElementById("clientNameDD").value = "";
    setTimeout(() => {
      [...document.getElementsByClassName("r-input-group")].forEach(element => {
        element.children[0].value = "";
      });
      document.getElementById("searchBtnClients").click();
    }, 500);
  };

  const removeTransactionsBilly = (transaction) => {

    const check = HideTransaction?.includes(transaction)
    return check;

  }

  const renderTransactionDetails = () => {
    return (
      <div class="row">
        <div class="col-md-12">
          <h3>Transaction Details</h3>
          <div class="mt-16">
            <form onSubmit={e => e.preventDefault()}>
              <ul class="ul-card-details-popup">
              <li class="d-flex justify-content-between mb-3">
                  <label>Date</label>
                  <span><DateFormat date={date} /></span>
                </li>
              
                <li class="d-flex justify-content-between mb-3">
                  <label>Merchant Name</label>
                  <span>{MerchantName === null ? TransactionsType : MerchantName}</span>
                </li>
                <li class="d-flex justify-content-between mb-3">
                  <label>Status</label>
                  <span>{Status}</span>
                </li>
                 
                <li class="d-flex justify-content-between mb-3">
                  <label>Amount</label>
                  <span>
                    <CurrencyFormat value={CurrencyCode} displayType={'text'} thousandSeparator={true} />&nbsp;{Amount}
                  </span>
                </li>
                {
                    currentUser?.user?.IssuerName === "IssuerB4B" && currentUser?.user?.ClientCurrency==="USD" && currentUser?.user?.IsPhysicalCard &&(
                      <li class="d-flex justify-content-between mb-3">
                      <label>Transaction Type</label>
                      <span>{TransactionsType}</span>
                    </li>
                    )
                  }
                <li class="d-flex justify-content-between mb-3">
                  <label>Ref #</label>
                  <span>{ReferenceNumber}</span>
                </li>
                {/* {
                  currentUser?.user?.IssuerName !== "IssuerG" &&
                  (
                    <li class="d-flex justify-content-between">
                      <label>Description</label>
                      <span>{Description}</span>
                    </li>
                  )
                } */}
              </ul>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const sortDateTransaction = (obj) => {
    let reArr = obj;
    reArr.sort((a, b) => {
      const dateA = new Date(a?.dateOfTransaction);
      const dateB = new Date(b?.dateOfTransaction);

      if (dateA < dateB) {
        return 1;
      } else if (dateA > dateB) {
        return -1;
      } else {
        return 0;
      }
    })
    setSearchData(reArr);
  }
  const calculateB4BFee = (transactionResponse, authAmount) => {
    let fee = transactionResponse?.split(",")
    if (currentUser?.user?.IssuerName === "IssuerB4B") {
      if(fee?.length>3){
        let len=fee?.length;
        let val;
        if(len ===3)
         val=(Number(fee[0])+Number(fee[1])+Number(fee[2]));
         if(len ===4)
         val=(Number(fee[0])+Number(fee[1])+Number(fee[2])+Number(fee[3]));
         if(len ===5)
         val=(Number(fee[0])+Number(fee[1])+Number(fee[2])+Number(fee[3])+Number(fee[4]));
        return val.toFixed(2);
      }
      else return authAmount
    }

  }
  return (
    <Fragment>
      <div className="row align-items-center mt-24">
        <div className="col-6">
          <h3> Overall Transactions</h3>
        </div>
        <div className="col-sm-6 toRight">
          <ExportToExcel
            Dataset={dataset}
            Name="Transaction"
            calledfrom="transaction"
          />
        </div>
      </div>
      <div className="">
        <div className="tab-content" id="myTabContent2">
          <div
            className="tab-pane fade show active"
            id="allClients"
            role="tabpanel"
            aria-labelledby="allClients-tab"
          >
            {/* no client found  */}
            <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
              <div className="text-center">
                <span className="icon-clients-empty">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                </span>
                <h3>Empty Clients Listing</h3>
                <h6>There is no Client(s) available for now.</h6>
              </div>
            </div>
            <Fragment>
              <form onSubmit={e => e.preventDefault()}>
                <div className="row row-search">
                  <div className="col-xl-12 col-12">
                    <div className="form-group">
                      <div className="form-controls">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={props.placeholdervalue}
                          onClick={handleInputSearchClick}
                          disabled={SearchInputDisabled}
                        />
                        <span className="icon-search _ico"></span>
                        {/* search box  */}
                        <div
                          className="bo-cardsearch"
                          style={{ display: modalShow ? "" : "none" }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <img
                                className="cross-icon cross-modal"
                                src={crossImgs}
                                aria-label="Close"
                                onClick={HideSearchPopupDiv}
                                alt=""
                              />
                            </div>
                            {
                              currentUser?.user?.IssuerName === "IssuerG" ? (<div className="col-md-6">
                                <div className="form-group form-search mr-20">
                                  <label>Month</label>
                                  <div className="form-controls">
                                    <select
                                      onChange={onChangeMonth}
                                      className="form-control no-icon"
                                      id="MonthIssuerG"
                                    >
                                      <option select="selected" value={Month}>
                                        Select
                                      </option>
                                      <option value={1}>
                                        January
                                      </option>
                                      <option value={2}>
                                        February
                                      </option>
                                      <option value={3}>
                                        March
                                      </option>
                                      <option value={4}>
                                        April
                                      </option>
                                      <option value={5}>
                                        May
                                      </option>
                                      <option value={6}>
                                        June
                                      </option>
                                      <option value={7}>
                                        July
                                      </option>

                                      <option value={8}>
                                        August
                                      </option>
                                      <option value={9}>
                                        September
                                      </option>
                                      <option value={10}>
                                        October
                                      </option>
                                      <option value={11}>
                                        November
                                      </option>
                                      <option value={12}>
                                        December
                                      </option>

                                    </select>
                                  </div>
                                </div>
                              </div>) : (<div className="col-md-6">
                                <div className="form-group form-search mr-20">
                                  <label>Date From</label>
                                  <div className="form-controls">
                                    <ReactDatePickerHelper
                                      datevalue={StartDate}
                                      onchangedate={dateFromHandler}
                                    />
                                  </div>
                                </div>
                              </div>)
                            }
                            {currentUser?.user?.IssuerName === "IssuerG" ? (<div className="col-md-6">
                              <div className="form-group form-search mr-20">
                                <label>Year</label>
                                <div className="form-controls">
                                  <select
                                    onChange={onChangeYear}
                                    className="form-control no-icon"
                                    id="YearIssuerG"
                                  >
                                    <option select="selected" value={0}>
                                      Select
                                    </option>
                                    <option value={2022}>
                                      2022
                                    </option>
                                    <option select="selected" value={2023}>
                                      2023
                                    </option>
                                    <option select="selected" value={2024}>
                                      2024
                                    </option>
                                    <option select="selected" value={2025}>
                                      2025
                                    </option>
                                    <option select="selected" value={2026}>
                                      2026
                                    </option>
                                    <option select="selected" value={2027}>
                                      2027
                                    </option>
                                    <option select="selected" value={2028}>
                                      2028
                                    </option>
                                    <option select="selected" value={2029}>
                                      2029
                                    </option>
                                    <option select="selected" value={2030}>
                                      2030
                                    </option>

                                  </select>
                                </div>
                              </div>
                            </div>) : (<div className="col-md-6">
                              <div className="form-group form-search mr-20">
                                <label>Date To</label>
                                <div className="form-controls">
                                  <ReactDatePickerHelper
                                    datevalue={EndDate}
                                    onchangedate={dateToHandler}
                                  />
                                </div>
                              </div>
                            </div>)}


                            <div className="col-md-12">
                              <div className="form-group submit-field form-search">
                                {currentUser?.user?.IssuerName === "IssuerG" ? (<button
                                  id="searchBtnClients"
                                  className="btn btn-outline mr-19"
                                  onClick={GetCardTransactionsspecificmonth}
                                >
                                  Search
                                </button>) : (<button
                                  id="searchBtnClients"
                                  className="btn btn-outline mr-19"
                                  onClick={SearchClickHandler}
                                >
                                  Search
                                </button>)}

                                <button
                                  className="btn btn-outline"
                                  onClick={ClearSearchData}
                                >
                                  Clear Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="help-block d-none"></span>
                    </div>
                  </div>
                </div>
              </form>
            </Fragment>
          </div>

          {searchGridSuccess && (
            <div className="table-wrap" ref={searchTbl}>
              <table
                className="table table-borderless bo-table table-cards"
              //onScroll={tblScrollHandler}
              //onWheel={tblScrollHandler}
              >
                <thead>
                  <tr>{renderTableHeader()}</tr>
                </thead>
                <tbody>{renderTableData()}</tbody>
              </table>
            </div>
          )}
          {loadingData ? (
            <div className="loading-more">
              <div className="loader">
                <div></div>
              </div>
              <p>loading data</p>
            </div>
          ) : totalCount === 0 ? (
            <div className="d-flex align-content-center justify-content-center no-clients-msg ">
              <div className="text-center">
                <span className="icon-transaction-empty">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                </span>
                <h3>Empty Transactions Listing</h3>
                <h6>There are no Transactions available for now.</h6>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <ModelPopup
          key={1}
          show={modalShowTransactions}
          onHide={() => setModalShowTransactions(false)}
          modelheading={""}
          modelbody={renderTransactionDetails()}
          showheader={"false"}
          dialogClassName="modal-dialog modal-dialog-centered mwidth-52"
          className="smallPopup issueCardSmall"
          backdrop="static"
          keyboard={false}
        />
      </div>
    </Fragment>
  );

};

export default SearchGridCardTransactions;
