import { Fragment,useState } from "react";
import { Redirect } from "react-router-dom";
import CHDashBoard from "../cardholderCardScreens/CHDashBoard";
import { logout } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { haspermissions } from "../permissions/PermissionTypes";
import RegisterGlobal from "../issuerGlobal/RegisterGlobal";
import RegisterIssuerT from "../issuerGlobal/RegisterIssuerT";
const Dashboard = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  if (!currentUser) {
    dispatch(logout());
    return <Redirect to="/login" />;
  }
if(currentUser)
    haspermissions.CardToCard=currentUser?.user?.Permissions?.includes("Card to Card Transfer");
if(currentUser?.user?.IsPhysicalCard && !currentUser?.user?.IsCardActivate && (currentUser?.user?.IssuerName!=="IssuerG" && currentUser?.user?.IssuerName!=="IssuerT"))
return <Redirect to="/cardverification" />;
if(currentUser?.user?.KycApprovedByIssuer=="Yes" && !currentUser?.user?.IsCardActivate && (currentUser?.user?.IssuerName =="IssuerG" || currentUser?.user?.IssuerName==="IssuerT"))
return <Redirect to="/cardverification" />;
  return (
    <Fragment>
{((!currentUser?.user?.IsKycSubmitted ||currentUser?.user?.KycApprovedByIssuer===null ) && currentUser?.user?.IssuerName==="IssuerG") 
?(<RegisterGlobal/>) : ((!currentUser?.user?.IsKycSubmitted ||currentUser?.user?.KycApprovedByIssuer===null ) && currentUser?.user?.IssuerName==="IssuerT") ?(<><RegisterIssuerT/></>)
:
(
  <Fragment>
{ <CHDashBoard  sendmoneypermission={haspermissions?.CardToCard} isphysicalcard={currentUser?.user?.IsPhysicalCard } clientid={currentUser?.user?.ClientId} />}
  </Fragment>
  
)}
      

    </Fragment>
  );

};

export default Dashboard;
