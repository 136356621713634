import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_MESSAGE,PRELOGIN,REFRESH_TOKEN } from "./types";

import AuthService from "../services/auth.service";

export const login = (username, password, rememberMe,emailcode,smscode) => (dispatch) => {
  return AuthService.login(username, password, rememberMe,emailcode,smscode).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.login_failure) ||
        error.response.data.email_confirmed ||
        (error.response.data && error.response.data[""]) ||
        error.response.data ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const prelogin = (username, password) => (dispatch) => {
  return AuthService.prelogin(username, password).then(
    (data) => {
      dispatch({
        type: PRELOGIN,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.login_failure) ||
        error.response.data.email_confirmed ||
        (error.response.data && error.response.data[""]) ||
        error.response.data ||
        error.toString();

      dispatch({
        type: PRELOGIN,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const loginCardConsole = (username, password, rememberMe) => (dispatch) => {
  return AuthService.loginCardConsole(username, password, rememberMe).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.login_failure) ||
        error.response.data.email_confirmed ||
        (error.response.data && error.response.data[""]) ||
        error.response.data ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const loginData = (UserId) => (dispatch) => {
  return AuthService.loginData(UserId).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.login_failure) ||
        error.response.data.email_confirmed ||
        (error.response.data && error.response.data[""]) ||
        error.response.data ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
export const refreshToken = (accessToken) => (dispatch) => {
  dispatch({
    type: REFRESH_TOKEN,
    payload: accessToken
  });
};