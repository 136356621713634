import stackedcards1 from "../../assets/images/card-theme1.svg";
import stackedcards2 from "../../assets/images/card-theme2.svg";
import stackedcards3 from "../../assets/images/card-theme3.svg";
import stackedcards4 from "../../assets/images/card-theme4.svg";
import stackedcards5 from "../../assets/images/card-theme5.svg";
import stackedcards6 from "../../assets/images/card-theme6.svg";
import stackedcards7 from "../../assets/images/card-theme7.svg";
import stackedcards8 from "../../assets/images/card-theme8.svg";
import stackedcards9 from "../../assets/images/card-theme9.svg";
import wifiSignalCard from "../../assets/images/wifi-signal.svg";
import tickRound from "../../assets/images/tick-round.svg";
import eyeOff from "../../assets/images/eye-off.svg";
import eyeOn from "../../assets/images/eye-on.svg";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cardholdercardsObj from "../../services/cardholder-cards.service";
import moment from "moment";
import RefreshToken from "../../helpers/RefreshToken";
import CurrencyFormat from 'react-currency-format';
import KapedCard from "../kapedCard/KapedCard";
import AmberCard from "../AmberCard/AmberCard";
import KapedNewCard from "../KapedCardNew/KapedNewCard";
var classkaped="text-center"
var classkaped1="carousel slide mb-32 text-center"
var imagepath = stackedcards1;
const CardDetails = (props) => {

  const { user: currentUser } = useSelector((state) => state.auth);
  const [UserId, setUserId] = useState(currentUser?.user?.UserId);
  const [ClientBalance, setClientBalance] = useState(0);
  const [CardNumber, setCardNumber] = useState();
  const [MaskedNumber, setMaskedNumber] = useState();
  const [IconShown, setIconShown] = useState(true);
  const [TokenId, setTokenId] = useState();
  const [ValidThru, setValidThru] = useState();
  const [clientCurrency, setClientCurrency] = useState("");
  const [creditBalance, setCreditBalance] = useState("");

  useEffect(() => {
    getCardNumber();
    checkClientCurrency();
    cardholdercardsObj.creditLimitCalculation(UserId).then((resp) => {
      setCreditBalance(resp?.data?.payload?.result?.totalLimit);
    }).catch((error) => {

    });

  }, [])

  useEffect(() => {

    superAdminBalance();

  }, [])
  //Payment Processor Nium or mSwipe
  const [PaymentProcessor, setPaymentProcessor] = useState(String(currentUser?.user?.PaymentProcessor));
if(currentUser?.user?.ClientId ===35)
{
  classkaped=""
  classkaped1="carousel slide mb-32"
}


  if (currentUser) {
    var Themename = currentUser?.user?.ThemeName;
    if (Themename === "theme1") imagepath = stackedcards1;
    if (Themename === "theme2") imagepath = stackedcards2;
    if (Themename === "theme3") imagepath = stackedcards3;
    if (Themename === "theme4") imagepath = stackedcards4;
    if (Themename === "theme5") imagepath = stackedcards5;
    if (Themename === "theme6") imagepath = stackedcards6;
    if (Themename === "theme7") imagepath = stackedcards7;
    if (Themename === "theme8") imagepath = stackedcards8;
    if (Themename === "theme9") imagepath = stackedcards9;
  }



  const getCardNumber = () => {
    cardholdercardsObj.getCardNumber(UserId).then(
      (response) => {
        let cardnumber = response.data.payload.result.unMaskedCardNumber.match(/.{1,4}/g);
        let setcard = cardnumber[0] + '-' + cardnumber[1] + '-' + cardnumber[2] + '-' + cardnumber[3];
        setCardNumber(setcard);
        setTokenId(response.data.payload.result.tokenId);
        setMaskedNumber(response.data.payload.result.maskedCardNumber.toUpperCase())
        setValidThru(moment(response.data.payload.result.expiryDate).format("MM/YY")
        );
      },

    ).catch((error) => {
      (async () => {
        let resp = await RefreshToken(
          error && error.response && error.response.status
        );
        if (resp) {
          getCardNumber();
        }
      })();
    });

  }
 
  const superAdminBalance = () => {
    if (currentUser?.user?.IsPhysicalCard) {
      cardholdercardsObj.getBalancePhysical(UserId).then(
        (response) => {
          if (response && response.data && response.data.payload)
            setClientBalance(response.data.payload.result.item1.balance);

        },
        (error) => {
          console.log("error is here")
        }
      ).catch((error) => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            superAdminBalance();
          }
        })();
      });;
    }
    else {
      cardholdercardsObj.getSuperAdminBalance(UserId).then(
        (response) => {
          if (response && response.data && response.data.payload)
            setClientBalance(response.data.payload.result.item1.balance);

        },
        (error) => {
          console.log("error is here")
        }
      ).catch((error) => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            superAdminBalance();
          }
        })();
      });;
    }
  }

  const showHideIcon = () => {
    setIconShown(IconShown ? false : true);

  };
  const checkClientCurrency = () => {
    if (currentUser?.user?.ClientCurrency === "USD")
      setClientCurrency("USD")
    else if (currentUser?.user?.ClientCurrency === "EUR")
      setClientCurrency("EUR")
    else if (currentUser?.user?.ClientCurrency === "GBP")
      setClientCurrency("GBP")
    else if (currentUser?.user?.ClientCurrency === "CAD")
      setClientCurrency("CAD")
    else
      setClientCurrency("")
  }
  return (
    <Fragment>
      {props.cht === "Y" && (
        <div  className={classkaped}>
          <Fragment>
            {(currentUser?.user?.ClientId ===35) ?(<KapedNewCard cardNumber={CardNumber} cardExpiry={ValidThru} creditBalance={creditBalance} 
            ClientBalance={ClientBalance} clientCurrency={clientCurrency}/>):
            ( currentUser?.user?.ClientId ===(28) || currentUser?.user?.ClientId ===(37) ||
            currentUser?.user?.ClientId ===(45) ||currentUser?.user?.ClientId ===(46))?(<AmberCard cardNumber={CardNumber} cardExpiry={ValidThru} creditBalance={creditBalance}
              ClientBalance={ClientBalance} clientCurrency={clientCurrency} MaskedNumber={MaskedNumber}
              TokenId={TokenId}
            />)
            :(
               <div
               className="bo-credit-card"
               style={{ backgroundImage: `url(${imagepath})` }}>
               <div class="card-bgs">
                 {/* x */}
               </div>
               <div className="bo-credit-card-inner">
                 <div className="d-flex align-items-center top-bar">
                   <div className="top-bar-left">
                     <span></span>
                   </div>
                   <div className="top-bar-right ml-auto dull-label">
                     {currentUser?.user?.IsPhysicalCard ? (<span>Physical Card</span>) : (<span>Virtual Card</span>)}
                     <img className="ml-1" src={wifiSignalCard} />
                   </div>
                 </div>
                 <div className="account-balance mb-20">
                   {/* <h1>{ClientBalance} {PaymentProcessor==="MSwipe" ?"USD":"EUR"}</h1> */}
                   <h1><CurrencyFormat value={currentUser?.user?.CardFlow === "CREDIT" ?
                     (creditBalance - ClientBalance) > 0 ? creditBalance - ClientBalance : 0 : ClientBalance}
                     displayType={'text'} thousandSeparator={true} />&nbsp;{clientCurrency}</h1>
                 </div>
                 <div className="d-flex mb-2">
                   <div className="card-no d-flex">
                     {
                       IconShown && (
                         <span>{MaskedNumber}</span>
                       )
                     }
                     {
                       !IconShown && (
                         <span>{CardNumber}</span>
                       )
                     }
 
                   </div>
                   <div className="ml-auto" onClick={showHideIcon}>
                     <span className="black-circle d-flex align-items-center justify-content-center">
                       {!IconShown && (<img src={eyeOff} />)}
                       {IconShown && (<img src={eyeOn} />)}
                     </span>
                   </div>
                 </div>
                 <div className="d-flex">
                 {!currentUser?.user?.IsPhysicalCard &&(
                    <div className="label-plus-value dull-label">
                    <span>Valid Thru</span>
                    <p>{ValidThru}</p>
                  </div>
                  )}
                   
                   <div className="label-plus-value dull-label">
                     <span>Token ID</span>
                     <p>{TokenId}</p>
                   </div>
                   <div className="label-plus-value dull-label">
                     <span>Currency</span>
                     {/* <p>{PaymentProcessor==="MSwipe" ?"USD":"EUR"}</p> */}
                     <p>{clientCurrency}</p>
                   </div>
                   <div className="label-plus-value dull-label">
                     <span>Status</span>
                     <p>
                       <img src={tickRound} />
                       Active
                     </p>
                   </div>
                 </div>
               </div>
             </div>
            )}
           
            
          </Fragment>
        </div>
      )}
      {props.cht === "N" && (
        <div className="col-xl-6 col-md-6 col-sm-6">
          <div
            id="myCards"
            className={classkaped1}
            data-bs-ride="carousel"
          >
          {(currentUser?.user?.ClientId ===35) ?(<KapedNewCard cardNumber={CardNumber} cardExpiry={ValidThru} creditBalance={creditBalance} 
            ClientBalance={ClientBalance} clientCurrency={clientCurrency}/>):
          ( currentUser?.user?.ClientId ===(28) || currentUser?.user?.ClientId ===(37)||
          currentUser?.user?.ClientId ===(45) ||currentUser?.user?.ClientId ===(46))?(<AmberCard cardNumber={CardNumber} cardExpiry={ValidThru} creditBalance={creditBalance}
            ClientBalance={ClientBalance} clientCurrency={clientCurrency} MaskedNumber={MaskedNumber}
            TokenId={TokenId}
          />)
          :(
            <div
            className="bo-credit-card"
            style={{ backgroundImage: `url(${imagepath})` }}
          >
            <div class="card-bgs">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="bo-credit-card-inner">
              <div className="d-flex align-items-center top-bar">
                <div className="top-bar-left">
                  <span></span>
                </div>
                <div className="top-bar-right ml-auto dull-label">
                  {currentUser?.user?.IsPhysicalCard ? (<span>Physical Card</span>) : (<span>Virtual Card</span>)}
                  <img className="ml-1" src={wifiSignalCard} />
                </div>
              </div>
              <div className="account-balance mb-20">
                {/* <h1>{ClientBalance}  {PaymentProcessor==="MSwipe" ?"USD":"EUR"}</h1> */}
                <h1><CurrencyFormat value={currentUser?.user?.CardFlow === "CREDIT" ?
                  (creditBalance - ClientBalance) > 0 ? creditBalance - ClientBalance : 0 : ClientBalance} displayType={'text'} thousandSeparator={true} />&nbsp;{clientCurrency}</h1>
              </div>
              <div className="d-flex mb-2">
                <div className="card-no d-flex">
                  {
                    IconShown && (
                      <span>{MaskedNumber}</span>
                    )
                  }
                  {
                    !IconShown && (
                      <span>{CardNumber}</span>
                    )
                  }

                </div>
                <div className="ml-auto" onClick={showHideIcon}>
                  <span className="black-circle d-flex align-items-center justify-content-center">
                    {!IconShown && (<img src={eyeOff} />)}
                    {IconShown && (<img src={eyeOn} />)}
                  </span>
                </div>
              </div>
              <div className="d-flex">
              {!currentUser?.user?.IsPhysicalCard &&(
                    <div className="label-plus-value dull-label">
                    <span>Valid Thru</span>
                    <p>{ValidThru}</p>
                  </div>
                  )}
                
                <div className="label-plus-value dull-label">
                  <span>Token ID</span>
                  <p>{TokenId}</p>
                </div>
                <div className="label-plus-value dull-label">
                  <span>Currency</span>
                  {/* <p>{PaymentProcessor==="MSwipe" ?"USD":"EUR"}</p> */}
                  <p>{clientCurrency}</p>
                </div>
                <div className="label-plus-value dull-label">
                  <span>Status</span>
                  <p>
                    <img src={tickRound} />
                    Active
                  </p>
                </div>
              </div>
            </div>
          </div>
          )}
            
          </div>
        </div>
      )}
      {props.calledfrom === "updatepin" && (
        <div className="">
          <div
            id="myCards"
            className="carousel slide mb-32 text-center"
            data-bs-ride="carousel"
          >
            {/* <img className="img-fluid" src={imagepath} alt="..." /> */}
            <div
              className="bo-credit-card"
              style={{ backgroundImage: `url(${imagepath})` }}
            >
              <div class="card-bgs">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="bo-credit-card-inner">
                <div className="d-flex align-items-center top-bar">
                  <div className="top-bar-left">
                    <span>Bo Payment</span>
                  </div>
                  <div className="top-bar-right ml-auto dull-label">
                    {currentUser?.user?.IsPhysicalCard ? (<span>Physical Card</span>) : (<span>Virtual Card</span>)}
                    <img className="ml-1" src={wifiSignalCard} />
                  </div>
                </div>
                <div className="account-balance mb-20">
                  {/* <h1>{ClientBalance}  {PaymentProcessor==="MSwipe" ?"USD":"EUR"}</h1> */}
                  <h1><CurrencyFormat value={currentUser?.user?.CardFlow === "CREDIT" ?
                    (creditBalance - ClientBalance) > 0 ? creditBalance - ClientBalance : 0 : ClientBalance} displayType={'text'} thousandSeparator={true} />&nbsp;{clientCurrency}</h1>
                </div>
                <div className="d-flex mb-2">
                  <div className="card-no d-flex">
                    {
                      IconShown && (
                        <span>{MaskedNumber}</span>
                      )
                    }
                    {
                      !IconShown && (
                        <span>{CardNumber}</span>
                      )
                    }

                  </div>
                  <div className="ml-auto" onClick={showHideIcon}>
                    <span className="black-circle d-flex align-items-center justify-content-center">
                      {!IconShown && (<img src={eyeOff} />)}
                      {IconShown && (<img src={eyeOn} />)}
                    </span>
                  </div>
                </div>
                <div className="d-flex">
                  {!currentUser?.user?.IsPhysicalCard &&(
                    <div className="label-plus-value dull-label">
                    <span>Valid Thru</span>
                    <p>{ValidThru}</p>
                  </div>
                  )}
                  
                  <div className="label-plus-value dull-label">
                    <span>Token ID</span>
                    <p>{TokenId}</p>
                  </div>
                  <div className="label-plus-value dull-label">
                    <span>Currency</span>
                    {/* <p>{PaymentProcessor==="MSwipe" ?"USD":"EUR"}</p> */}
                    <p>{clientCurrency}</p>
                  </div>
                  <div className="label-plus-value dull-label">
                    <span>Status</span>
                    <p>
                      <img src={tickRound} />
                      Active
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>



      )}


    </Fragment>
  );
};
export default CardDetails;
