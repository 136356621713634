import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import CardLimit from "../cardholderCardScreens/CardLimits";
import tickRound from "../../assets/images/tick-round.svg";
import eyeOn from "../../assets/images/eye-on.svg";
import wifiSignalCard from "../../assets/images/wifi-signal.svg";

var primary;
var secondary;
var lighter1;
var lighter2;
var mainBg;
var menuBg;
var cardBg;
var selectedTab;
var tabHover;
var headingText;
var icon;
var line;
var textBox;
var font;
const PreviewTheme = props => {
  const [activeClass, setActiveClass] = useState("dashboard");

  const applyActiveClass = id => {
    setActiveClass(id);
  };
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const PrimaryColor = "#" + params.get("pri");
  const SecondaryColor = "#" + params.get("sec");
  const ColorPrimarylighter1 = "#" + params.get("lcf");
  const ColorPrimarylighter2 = "#" + params.get("lcs");
  const MainBg = "#" + params.get("mainBg");
  const MenuBg = "#" + params.get("menuBg");
  const CardBg = "#" + params.get("cardBg");
  const SelectedTab = "#" + params.get("selectedTab");
  const TabHover = "#" + params.get("tabHover");
  const HeadingText = "#" + params.get("headingText");
  const Icon = "#" + params.get("icon");
  const Line = "#" + params.get("line");
  const TextBox = "#" + params.get("textBox");
  const Font = "#" + params.get("font");
  useEffect(() => {
    addThemeVariables();
  }, []);
  const addThemeVariables = () => {
    primary = PrimaryColor;
    secondary = SecondaryColor;
    lighter1 = ColorPrimarylighter1;
    lighter2 = ColorPrimarylighter2;
    mainBg = MainBg;
    menuBg = MenuBg;
    cardBg = CardBg;
    selectedTab = SelectedTab;
    tabHover = TabHover;
    headingText = HeadingText;
    icon = Icon;
    line = Line;
    textBox = TextBox;
    font = Font;
    document.documentElement.style.setProperty("--color-primary", primary);
    document.documentElement.style.setProperty("--color-secondary", secondary);
    document.documentElement.style.setProperty(
      "--color-primary-lighter-1",
      lighter1
    );
    document.documentElement.style.setProperty(
      "--color-primary-lighter-2",
      lighter2
    );
    document.documentElement.style.setProperty("--color-main-bg", mainBg);
    document.documentElement.style.setProperty("--color-menu-bg", menuBg);
    document.documentElement.style.setProperty("--color-card-bg", cardBg);
    document.documentElement.style.setProperty("--color-selected-tab", selectedTab);
    document.documentElement.style.setProperty("--color-tab-hover", tabHover);
    document.documentElement.style.setProperty("--color-heading-text", headingText);
    document.documentElement.style.setProperty("--color-icon", icon);
    document.documentElement.style.setProperty("--color-line", line);
    document.documentElement.style.setProperty("--color-text-box", textBox);
    document.documentElement.style.setProperty("--color-font", font);
  };
  const removeThemeVariables = () => {
    document.documentElement.style.removeProperty("--color-primary", primary);
    document.documentElement.style.removeProperty(
      "--color-secondary",
      secondary
    );
    document.documentElement.style.removeProperty(
      "--color-primary-lighter-1",
      lighter1
    );
    document.documentElement.style.removeProperty(
      "--color-primary-lighter-2",
      lighter2
    );
  };
  const submitForm=(e)=>{
    e.preventDefault();  }
    const maxLengthCheck = (object) => {
      if (object.target.value.length > object.target.maxLength) {
       object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
      }
  return (
    <div className="bo-app-main">
      <header className="header-main-outer">
        <section className="header-main">
          <div className="row align-items-center position-relative position-md-static">
            <div className="col-12 p-static d-flex align-items-center justify-content-center">
              <div className="div-hamburger">
                <button className="hamburgers" type="button">
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
              <div className="logo d-flex align-items-center bo-thumb">
                <Link to="#">
                  <h3>
                    Card<span>Console</span>
                  </h3>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </header>

      <div className="row minh-row">
        <div className="col-lg-3 col-md-0 bo-sidebar-col" id="leftNav">
          <div className="bo-sidebar-outer">
            <Link
              to="/dashboard"
              className={
                activeClass === "dashboard" || activeClass === ""
                  ? "active"
                  : ""
              }
              onClick={() => applyActiveClass("dashboard")}
            >
              <div className="logo d-flex align-items-center bo-thumb">
                <Link to="#">
                  <h3>
                    Card<span>Console</span>
                  </h3>
                </Link>
              </div>
            </Link>
            <ul className="sidebar-ul">
              <Fragment>
                <Fragment>
                  <li>
                    <Link
                      id="dashboardNavLink"
                      className={activeClass === "dashboard" ? "active" : ""}
                      onClick={() => applyActiveClass("dashboard")}
                    >
                      <i className="icon-dashboard"></i>Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="cardsNavLink"
                      className={activeClass === "cards" ? "active" : ""}
                      onClick={() => applyActiveClass("cards")}
                    >
                      <i className="icon-card"></i>My Cards
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="moneyNavLink"
                      className={activeClass === "money" ? "active" : ""}
                      onClick={() => applyActiveClass("money")}
                    >
                      <i className="icon-transaction"></i>Send Money
                    </Link>
                  </li>

                  <li>
                    <Link
                      id="transactionNavLink"
                      className={
                        activeClass === "cardholdertransactions" ? "active" : ""
                      }
                      onClick={() => applyActiveClass("cardholdertransactions")}
                    >
                      <i className="icon-transaction"></i>Transactions
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="settingsNavLink"
                      className={
                        activeClass === "cardHolder/settings" ? "active" : ""
                      }
                    >
                      <i className="icon-settings"></i>Settings
                    </Link>
                  </li>
                  <li>
                    <Link
                      id="helpNavLink"
                      className={activeClass === "help" ? "active" : ""}
                    >
                      <i className="icon-help"></i>Help
                    </Link>
                  </li>
                </Fragment>
              </Fragment>

              <hr />
              <li>
                <Link
                  className="d-flex align-items-center logout"
                >
                  <i className="icon-logout"></i>
                  <span>
                    <span>Logout</span>
                    <br />
                    <span className="user-name">{"preview theme"}</span>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xl-6 col-lg-8 col-md-12 bo-main-content">
          <h3>
            Your Cards<span className="bo-badge bo-badge-large">1</span>
          </h3>
          <div className="row mt-20">
            <div className="col-xl-6 col-md-6 col-sm-6 mb-20">
              <div className="text-center">
                <Fragment>
                  <div className="bo-credit-card">
                    <div class="card-bgs">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                    <div className="bo-credit-card-inner">
                      <div className="d-flex align-items-center top-bar">
                        <div className="top-bar-left">
                          <span>Bo Payment</span>
                        </div>
                        <div className="top-bar-right ml-auto dull-label">
                          <span>Virtual Card</span>
                          <img className="ml-1" src={wifiSignalCard} />
                        </div>
                      </div>
                      <div className="account-balance mb-20">
                        <h1>
                          {"60"} {"EUR"}
                        </h1>
                      </div>
                      <div className="d-flex mb-2">
                        <div className="card-no d-flex">
                          <span>{"4611-35XX-XXXX-0638"}</span>
                        </div>
                        <div className="ml-auto">
                          <span className="black-circle d-flex align-items-center justify-content-center">
                            <img src={eyeOn} />
                          </span>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="label-plus-value dull-label">
                          <span>Valid Thru</span>
                          <p>{"09/26"}</p>
                        </div>
                        <div className="label-plus-value dull-label">
                          <span>Token ID</span>
                          <p>{"8262527"}</p>
                        </div>
                        <div className="label-plus-value dull-label">
                          <span>Currency</span>
                          <p>{"EUR"}</p>
                        </div>
                        <div className="label-plus-value dull-label">
                          <span>Status</span>
                          <p>
                            <img src={tickRound} />
                            Active
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              </div>
            </div>
            <CardLimit />
          </div>
          <Fragment>
            <div className="row align-items-center mt-14">
              <div className="col-6">
                <h3> Overall Transactions</h3>
              </div>
            </div>
            <div className="mt-0 mt-sm-4">
              <div className="tab-content" id="myTabContent2">
                <div
                  className="tab-pane fade show active"
                  id="allClients"
                  role="tabpanel"
                  aria-labelledby="allClients-tab"
                >
                  {/* no client found  */}
                  <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
                    <div className="text-center">
                      <span className="icon-clients-empty">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                        <span className="path5"></span>
                        <span className="path6"></span>
                      </span>
                      <h3>Empty Clients Listing</h3>
                      <h6>There is no Client(s) available for now.</h6>
                    </div>
                  </div>
                </div>

                <div className="table-wrap">
                  <table className="table table-borderless bo-table table-cards">
                    <thead>
                      <th>TRANSACTION TYPE</th>
                      <th>STATUS</th>
                      <th>AMOUNT</th>
                      <th>DATE</th>
                      <th>REF#</th>
                      <th>VIEW</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex bo-col-transaction">
                            <div>
                              <h6>DEBIT</h6>
                              
                            </div>
                          </div>
                        </td>
                        <td>Approved</td>
                        <td>50 EUR</td>
                        <td>March 23, 2021</td>
                        <td>FT1889404873</td>
                        <td>
                          <div className="dropdown d-inline-block table_menu_action">
                            <a
                              className="dropdown-toggle no-caret"
                              id="action_menu"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="icon-show"></span>
                            </a>

                            <div
                              className="dropdown-menu dropdown-menu-end action__menu"
                              aria-labelledby="action_menu"
                            >
                              <div className="action_menu">
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Erat enim elit, netus
                                  imperdiet quis leo. Nec pharetra feugiat dui,
                                  et id duis viverra.
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex bo-col-transaction">
                            <div>
                              <h6>Receiving</h6>
                              <p> 2808988</p>
                            </div>
                          </div>
                        </td>
                        <td>March 23, 2021</td>
                        <td>5:11 PM</td>
                        <td>Deposit</td>
                        <td>+$800</td>
                        <td>
                          <div className="dropdown d-inline-block table_menu_action">
                           
                              <span className="icon-show"></span>
                           
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Fragment>
        </div>
        <div className="col-xl-3 col-lg-4 col-lg-0 bo-right-sidebar">
          <div className="bo-right-sidebar-wrap">
            <div className="row align-items-center">
              <div className="col-7"></div>
              <div className="col-5">
                <div className="a-arrow-btn toRight ml--6"></div>
              </div>
            </div>

            <div className="row align-items-center favorites mt-20">
              <div className="col-9">
                <p>Send Money to Favourites</p>
              </div>
              <div className="col-3"></div>
            </div>
            <ul className="ul-card-details-popup mt-16 ul-dashboard mb-5">
              <li className="d-flex justify-content-between mb-20 li-send-money">
                <div className="d-flex align-items-center">
                  <span className="td-logo">JW</span>
                  <b>Jane Will</b>
                </div>
                <span>4563698</span>
              </li>
              <li className="d-flex justify-content-between mb-20 li-send-money">
                <div className="d-flex align-items-center">
                  <span className="td-logo">KW</span>
                  <b>Kane Will</b>
                </div>
                <span>5698742</span>
              </li>
            </ul>
            <hr />
            <div className="mt-40">
              <section>
                <h3>Send Money</h3>
                <form onSubmit={submitForm}>
                  <div className="row mt-20">
                    <div className="col-md-12">
                      <div className="form-group" id="id_AccountNumber">
                        <label for="email">Token ID</label>
                        <div className="form-controls">
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="form-control"
                            name="AccountNumber"
                            maxLength = "7"
                            onInput={maxLengthCheck}
                          />
                          <span className="icon-card-check _ico"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group" id="id_Amount">
                        <label for="email">Amount</label>
                        <div className="form-controls">
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="form-control"
                            name="Amount"
                          />
                          <span className="icon-money clr-dustpink _ico"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Currency Code*</label>
                        <div className="form-controls">
                          <select className="form-control no-icon">
                            <option select="selected" value="USD">
                              USD
                            </option>
                            <option select="selected" value="EUR">
                              EUR
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group submit-field">
                        <button className="btn btn-large w-100" onClick={submitForm}>
                          Send Money
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewTheme;
